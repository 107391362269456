'use strict';
import {html, css, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('simple-greeting')
export class SimpleGreeting extends LitElement {
    styles = css`p { 
        color: pink;
        background-color: red;
    }`;

    @property()
    name :string = 'World';

    override render() {
        return html`<p>Hello, ${this.name}!</p>`;
    }
}